import { Text } from "@chakra-ui/react";
import { useMDXComponents } from "@mdx-js/react";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import remarkSmartypants from "remark-smartypants";

export const Markdown = ({
  content,
  inline = false,
}: {
  content: string;
  inline?: boolean;
}) => {
  const components = useMDXComponents((components) => ({
    ...components,
    ...(inline ? { p: (props) => <Text as="span" {...props} /> } : {}),
  }));

  const remarkPlugins = [
    remarkGfm,
    remarkMath,
    [remarkSmartypants, { dashes: "oldschool" }],
  ];

  const rehypePlugins = [[rehypeKatex, { strict: false }]];

  return (
    <ReactMarkdown
      // @ts-expect-error: trust me
      components={components}
      // @ts-expect-error: trust me
      remarkPlugins={remarkPlugins}
      // @ts-expect-error: trust me
      rehypePlugins={rehypePlugins}
    >
      {content}
    </ReactMarkdown>
  );
};

import { Image as ChakraImage } from "@chakra-ui/react";
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  SourceHTMLAttributes,
} from "react";
import { Markdown } from "./Markdown";
import { Text } from "./Text";
import { useColors } from "./useColors";

type LowercaseKeys<T> = { [P in keyof T as Lowercase<string & P>]: T[P] };

const Caption = ({ caption }: { caption?: string }) => {
  const { subtitleColor } = useColors();

  if (!caption) {
    return null;
  }

  return (
    <Text
      as="figcaption"
      color={subtitleColor}
      textAlign="center"
      fontFamily="sans"
      wrap="balance"
      my="parSpace"
    >
      <Markdown content={caption} inline={true} />
    </Text>
  );
};

export const Image = (
  props: Omit<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    "src"
  > & {
    src: string | LowercaseKeys<SourceHTMLAttributes<HTMLSourceElement>>[];
    caption?: string;
  },
) => {
  const { caption, src } = props;
  if (typeof src === "string") {
    return (
      <figure>
        <picture>
          {/* @ts-expect-error: the props should match in this case */}
          <ChakraImage {...props} mx="auto" my="parSpace" />
        </picture>
        <Caption caption={caption} />
      </figure>
    );
  }

  const fixAttrs = (attrs: (typeof src)[number]) => {
    const { srcset, ...rest } = attrs;
    return { srcSet: srcset, ...rest };
  };

  return (
    <figure>
      <picture>
        {src.map((attrs, i) => (
          <source key={i} {...fixAttrs(attrs)} />
        ))}
        <ChakraImage {...fixAttrs(src.at(-1)!)} mx="auto" my="parSpace" />
      </picture>
      <Caption caption={caption} />
    </figure>
  );
};

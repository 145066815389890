import { Flex, Tooltip } from "@chakra-ui/react";
import { createContext, ReactNode, useContext, useRef } from "react";
import { Markdown } from "./Markdown";
import { Link } from "./Link";

type Footnote = {
  content: string;
  label?: string;
};

const FootnoteContext = createContext<{
  get: () => Required<Footnote>[];
  push: (footnote: Footnote) => { label: string; index: number };
}>({
  get: () => [],
  push: () => {
    throw new Error("Not in FootnoteProvider");
  },
});

export const FootnoteProvider = ({ children }: { children: ReactNode }) => {
  const footnotes = useRef<Required<Footnote>[]>([]);
  return (
    <FootnoteContext.Provider
      value={{
        get: () => footnotes.current,
        push: (footnote) => {
          const label =
            footnote.label ??
            footnote.content.slice(0, 20).replaceAll(/\W/g, "").slice(0, 10);

          if (footnotes.current.some((f) => f.label === label)) {
            throw new Error(`Duplicate footnote label: ${label}`);
          }

          const index = footnotes.current.push({ label, ...footnote });

          return { label, index };
        },
      }}
    >
      {children}
    </FootnoteContext.Provider>
  );
};

export const Footnote = (props: Footnote) => {
  const { push } = useContext(FootnoteContext);
  const { label, index } = push(props);

  return (
    <Tooltip
      label={<Markdown content={props.content} inline={true} />}
      hasArrow
      placement="top"
    >
      <Link icon="" href={`#fn-${label}`} id={`fnref-${label}`}>
        {index}
      </Link>
    </Tooltip>
  );
};

export const Footnotes = () => {
  const { get } = useContext(FootnoteContext);

  return (
    <Flex direction="column">
      {get().map(({ label, content }, index) => (
        <Flex key={label}>
          <Link icon="" href={`#fnref-${label}`} id={`fn-${label}`}>
            {index}
          </Link>
          <Markdown content={content} />
        </Flex>
      ))}
    </Flex>
  );
};
